var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"pagination",staticStyle:{"cursor":"pointer"}},[_c('li',{class:{
        'page-item': true,
        'disabled': _vm.isFirstPage,
        'd-none': _vm.pages===2
        },on:{"click":function($event){return _vm.switchCurrentPage(1)}}},[_c('i',{staticClass:"fas fa-angle-double-left page-link"})]),_c('li',{class:{
        'page-item': true,
        'disabled': _vm.current===1
        },on:{"click":function($event){return _vm.switchCurrentPage(_vm.current-1)}}},[_c('i',{staticClass:"fas fa-angle-left page-link"})]),_c('li',{class:{
        'page-item': true,
        'active': _vm.current===1
        },on:{"click":function($event){return _vm.switchCurrentPage(_vm.valueB1)}}},[_c('a',{staticClass:"page-link"},[_vm._v(_vm._s(_vm.valueB1))])]),_c('li',{class:{
        'page-item': true,
        'active': _vm.current>1 && _vm.current!==_vm.pages || _vm.current===2
        },on:{"click":function($event){return _vm.switchCurrentPage(_vm.valueB2)}}},[_c('a',{staticClass:"page-link"},[_vm._v(_vm._s(_vm.valueB2))])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.pages>2),expression:"pages>2"}],class:{
        'page-item': true,
        'active': _vm.current===_vm.pages
        },on:{"click":function($event){return _vm.switchCurrentPage(_vm.valueB3)}}},[_c('a',{staticClass:"page-link"},[_vm._v(_vm._s(_vm.valueB3))])]),_c('li',{class:{
        'page-item': true,
        'disabled': _vm.current===_vm.pages
        },on:{"click":function($event){return _vm.switchCurrentPage(_vm.current+1)}}},[_c('i',{staticClass:"fas fa-angle-right page-link"})]),_c('li',{class:{
        'page-item': true,
        'disabled': _vm.isLastPage,
        'd-none': _vm.pages===2
        },on:{"click":function($event){return _vm.switchCurrentPage(_vm.pages)}}},[_c('i',{staticClass:"fas fa-angle-double-right page-link"})])])}
var staticRenderFns = []

export { render, staticRenderFns }