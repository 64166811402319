<template>

    <ul class="pagination" style="cursor: pointer">
        <!-- to first -->
        <li @click="switchCurrentPage(1)" :class="{
            'page-item': true,
            'disabled': isFirstPage,
            'd-none': pages===2
            }">
                <i class="fas fa-angle-double-left page-link"></i>
        </li>

        <!-- to left -->
        <li @click="switchCurrentPage(current-1)" :class="{
            'page-item': true,
            'disabled': current===1
            }">
                <i class="fas fa-angle-left page-link"></i>
        </li>

        <!-- avaliable pages -->
        <!-- 1 -->
        <li @click="switchCurrentPage(valueB1)" :class="{
            'page-item': true,
            'active': current===1
            }">
                <a class="page-link">{{ valueB1 }}</a>
        </li>
        <!-- 2 -->
        <li @click="switchCurrentPage(valueB2)" :class="{
            'page-item': true,
            'active': current>1 && current!==pages || current===2
            }">
                <a class="page-link">{{ valueB2 }}</a>
        </li>
        <!-- 3 -->
        <li @click="switchCurrentPage(valueB3)" v-show="pages>2" :class="{
            'page-item': true,
            'active': current===pages
            }">
                <a class="page-link">{{ valueB3 }}</a>
        </li>


        
        <!-- to right -->
        <li @click="switchCurrentPage(current+1)" :class="{
            'page-item': true,
            'disabled': current===pages
            }">
            
            <i class="fas fa-angle-right page-link"></i>
            
        </li>
        
        <!-- to last -->
        <li @click="switchCurrentPage(pages)" :class="{
            'page-item': true,
            'disabled': isLastPage,
            'd-none': pages===2
            }">
            
            <i class="fas fa-angle-double-right page-link"></i>
            
        </li>
    </ul>
  
</template>

<script>
/**
 * Componente de paginação :)
 */
export default {
    name: "pagination-admin",
    props: {
        /**
         * pagina atual
         */
        current: Number,

        /**
         * quantidade de paginas
         */
        pages: Number
    },
    computed: {
        /**
         * Verifica se está no começo da pagina
         */
        isFirstPage: function(){
            return this.current <= 2
        },

        /**
         * Verifica se está no final da pagina
         */
        isLastPage: function(){
            return this.current > this.pages-2
        },

        /**
         * valor do botao 1
         */
        valueB1: function(){
            // current>1 ? current-1 : 1
            if(this.current > 1 && this.current !== this.pages ){
                return this.current-1
            } if (this.current > 2 && this.current === this.pages) {
                return this.current-2
            } else {
                return 1
            }
        },

        /**
         * valor do botao 2
         */
        valueB2: function(){
            if(this.current > 1 && this.current !== this.pages ){
                return this.current
            } if (this.current === 2 && this.current === this.pages) {
                return this.current
            } if (this.current > 2 && this.current === this.pages) {
                return this.current-1
            } else {
                return this.current+1
            }

        },

        /**
         * valor do botao 3
         */
        valueB3: function(){
            if(this.current > 1 && this.current !== this.pages ){
                return this.current+1
            } if (this.current > 2 && this.current === this.pages) {
                return this.current
            } else {
                return this.current+2
            }
        }

    },
    methods: {
        /**
         * Metodo que emite um evento para trocar a pagina atual
         */
        switchCurrentPage: function(current){
            this.$emit('setCurrentPage', current)
        },

    }
}
</script>

<style>

</style>