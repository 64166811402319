<template>
  <div>
    <div class="div-companies content-wrapper">
        <section class="content-header p-1 px-2">
            <div class="div-header container-fluid">
                <div class="row mb-2 header-title-border">
                    <div class="col-sm-12 d-flex justify-content-between align-items-center">
                        <h4 class="mt-3 header-title-color">
                            <i class="fas fa-ticket-alt"></i>
                            Tickets
                        </h4>
                    </div>
                </div>
            </div>
        </section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div  v-if="!showTicket" class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                Total de tickets: {{totalTickets}}
                            </h3>
                            <div class="card-tools">
                                <div>
                                    <button
                                        class="btn btn-sm mr-1 mb-0 text-primary"
                                        title="Filtrar ticket por abertos"
                                        @click="getTicketsOpened()"
                                    >
                                        <i class="fas fa-clock"></i> Abertos
                                    </button>
                                    <button
                                        class="btn btn-sm mr-1 mb-0 text-danger"
                                        title="Filtrar ticket por encerrados"
                                        @click="getTicketsClosed()"
                                    >
                                        <i class="fas fa-times-circle"></i> Encerrados
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0 container-table">
                            <table class="table table-hover table-stripped table-head-fixed">
                                <thead>
                                <tr>
                                    <th class="id-th-width">ID</th>
                                    <th class="title-th-width">Título</th>
                                    <th>Data</th>
                                    <th>Categoria</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ticket, i) in tickets" :key="i" @click="openTicket(ticket)">
                                        <td class="td-last-accsess">
                                            #{{ticket.protocolo}}
                                        </td>
                                        <td>
                                            {{ ticket.titulo && ticket.titulo.length > 60 ? `${ticket.titulo.substr(0, 57)}...` : ticket.titulo }}
                                        </td>
                                        <td>
                                            {{ticket.data_criacao}}
                                        </td>
                                        <td>
                                            {{ ticket.categoria && ticket.categoria.length > 30 ? `${ticket.categoria.substr(0, 27)}...` : ticket.categoria }}
                                        </td>
                                        <td v-if="ticket.dataencerramento">
                                            <span class="badge badge-success">Encerrado</span>
                                        </td>
                                        <td v-else>
                                            <span class="badge badge-primary">Aberto</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="statusFindTickets" class="d-flex justify-content-center align-items-center mt-2 mb-2">
                                <div class="spinner-border spinner-size" role="status"/>
                                <span class="ml-2">Carregando Tickets...</span>
                            </div>
                            <hr class="mt-0"/>
                        </div>
                            <pagination
                                class="d-flex mt-2 justify-content-center"
                                :current="page"
                                :pages="totalPages"
                                @setCurrentPage="setCurrentPage($event)"
                            />
                    </div>
                    <div v-else class="card">
                        <section class="content">
                            <div class="card-header">
                                <h3 class="card-title mt-1">
                                    Detalhes do Ticket: #{{selectedTicket.protocolo}}
                                </h3>
                                <div v-if="showTicket" class="card-tools">
                                    <button class="btn btn-sm btn-outline-secondary" @click.prevent="closeTicket()">
                                        <i class="fa fa-arrow-left mx-1"></i>Voltar
                                    </button>
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Cliente:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.nomeorganizacao}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Titulo:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.titulo}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Descrição:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.mensagem.replaceAll(/(<([^>]+)>)/gi, "")}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Data Criação:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.data_criacao}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Prioridade:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{getPriority(selectedTicket.prioridade)}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Data Categoria:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.categoria}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Data Departamento:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.departamento}}
                                </div>
                            </div>
                            <hr class="mt-0 mb-2"/>
                            <div class="d-flex align-items-center col-12">
                                <div class="mr-2 col-3">
                                    <label class="word-custom" for="name-company">Atendente:</label>
                                </div>
                                <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                                    {{selectedTicket.atendente}}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import helpers from '@/assets/helpers'
import pagination from '../academy/components/Pagination.vue'

import moment from 'moment-timezone'
import "moment/locale/pt-br.js"

export default {
    components: { pagination },

    data() {
        return {
            tickets: [],
            totalTickets: 0,
            statusFindTickets: false,
            page: 1,
            showTicket: false,
            selectedTicket: {},
            filter: 'all'
        }
    },

    computed: {
        totalPages() {
            return this.totalTickets > 1 ? Math.round(this.totalTickets / 50) : 1;
        }
    },
    
    methods: {
        getTickets() {
            this.clearTickets();

            this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

            this.statusFindTickets = true;
            this.$tallos_admin_api.get(`tickets/${this.$route.params.id}/${this.page}/${this.filter}`)
                .then(res => {
                    this.tickets = res.data.data;
                    this.totalTickets = res.data.total_itens;
                    this.statusFindTickets = false;
                    this.$toasted.global.defaultSuccess({
                        msg: "Tickets encontrados."
                    });
                })
                .catch(e => {
                    console.log(e)
                    this.statusFindTickets = false;
                    this.$toasted.global.defaultError({
                        msg: "Erro ao buscar tickets."
                    });
                });
        },

        clearTickets () {
            this.tickets = [];
            this.totalTickets = 0;
        },

        openTicket(ticket) {
            this.showTicket = true;
            this.selectedTicket = ticket;
        },

        closeTicket() {
            this.showTicket = false;
            this.selectedTicket = {};
        },

        convertHTML(html) {
            const parser = new DOMParser()
            const virtualDoc = parser.parseFromString(html, 'text/html')

            return virtualDoc;
        },

        getPriority(level) {
            const priority = {
                1: 'Baixo',
                2: 'Normal',
                3: 'Alto',
                4: 'Urgente'
            }

            return priority[level];
        },

        setCurrentPage: async function(current){
            this.page = current > 0 && current <= this.totalPages ? current : this.page;
            await this.getTickets()
        },

        getTicketsOpened() {
            this.filter = this.filter == 'open' ? 'all' : 'open';
            this.getTickets();
        },

        getTicketsClosed() {
            this.filter = this.filter == 'close' ? 'all' : 'close';
            this.getTickets();
        }
    },

    created: function() {
        this.getTickets();
        document.title = 'Tallos Admin - Tickets'
    }
}
</script>

<style scoped>
.content-wrapper {
    background-color: #fff;
}

.vertical-line {
    display: block;
    height: 100%;
    border-right: 4px solid rgb(168, 168, 168);
}

.circle {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.header-title-border {
    border-bottom: 1px solid #0001
}

.header-title-color {
    color: #0007
}

.container-table {
    max-height: 70vh;
}

.id-th-width {
    width: 85px;
}

.title-th-width {
    width: 400px;
}

.spinner-size {
    max-height: 20px;
    max-width: 20px;
}
</style>
